import React, { Suspense, lazy } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// Styles
import "../styles/user/style.css";
import "../styles/user/mobile.css";
import "../styles/user/smalllp.css";

// Admin and SUper Admin
const AdminNavbar = lazy(() => import("./navigation/adminNav"));
const SuperAdminNavbar = lazy(() => import("./navigation/superAdminNav"));
const ActivityAdmin = lazy(() => import("../components/admin/CMS/activity"));
const SliderAdmin = lazy(() => import("../components/admin/CMS/homeSlider"));
const Festival1Admin = lazy(() => import("../components/admin/CMS/festival1"));
// const FestivalAdmin = lazy(() => import("../components/admin/CMS/festival"));
const DonationAdminAdmin = lazy(() =>
  import("../components/admin/CMS/donation")
);
const AdminDashboard = lazy(() => import("../pages/admin/Home"));
const SuperAdminDashboard = lazy(() => import("../pages/superAdmin/Home"));

// User Other
const UserHome = lazy(() => import("../pages/user/Home"));
const Login = lazy(() => import("../pages/login/Login"));
const Festival = lazy(() => import("../pages/user/Festival"));
const UserNav = lazy(() => import("./navigation/userNav"));
const UserFooter = lazy(() => import("../routes/footer/userFooter"));

// Donation All
const DonationAshadhi = lazy(() => import("../pages/user/DonationAshadhi"));
const DonationGau = lazy(() => import("../pages/user/Donation_Gau"));
const DonationAnna = lazy(() => import("../pages/user/Donation_Anna"));
const DonationGita = lazy(() => import("../pages/user/Donation_Gita"));
const DonationPilgrims = lazy(() => import("../pages/user/Donation_Pilgrims"));
const DonationYouth = lazy(() => import("../pages/user/Donation_Youth"));
const DonationGenral = lazy(() => import("../pages/user/Donation_genral"));

//NEws and Blog
const News = lazy(() => import("../pages/user/News"));
const Blog = lazy(() => import("../pages/user/Blog"));

// Contact and Media
const Contact = lazy(() => import("../pages/user/Contact"));
const VideoGallary = lazy(() => import("../pages/user/VideoGallary"));
const PhotoGallery = lazy(() => import("../pages/user/PhotoGallary"));
const DailyDarshan = lazy(() => import("../pages/user/DailyDarshan"));

//Booking
const Acco = lazy(() => import("../pages/user/Accomodation"));
const Food = lazy(() => import("../pages/user/FoodBooking"));
const Vehicle = lazy(() => import("../pages/user/Vechical"));
const DhamDarshan = lazy(() => import("../pages/user/DhamDarshan"));

// About
const AboutIP = lazy(() => import("../pages/user/About_IP"));
const AboutISKCON = lazy(() => import("../pages/user/About_ISKCON"));
const AboutLNS = lazy(() => import("../pages/user/About_LNS"));
const AboutPrabhupada = lazy(() => import("../pages/user/About_Prabhupada"));
const MahaKumbh = lazy(() => import("../components/user/Home/mahaKumbh"));
// not Found page
const NotFound = lazy(() => import("../pages/user/NotFound"));
const authenticateUser = () => {
  const isAuthenticated = true;
  const role = "admin";
  return { isAuthenticated, role };
};

class Routing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false,
    };
  }

  handleDatafromNav = (data) => {
    this.setState({
      navExpanded: data,
    });
  };

  render() {
    const { isAuthenticated, role } = authenticateUser();

    return (
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/login" exact>
              {isAuthenticated ? (
                role === "admin" ? (
                  <Redirect to="/admin/dashboard" />
                ) : (
                  <Redirect to="/superadmin/dashboard" />
                )
              ) : (
                <Login />
              )}
            </Route>
            {/* User Dashboard  */}
            <Route path="/" exact>
              <UserNav />
              <UserHome />
              <UserFooter />
            </Route>
            {/* Festival */}
            <Route path="/festival/:readmorelink" exact>
              <UserNav />
              <Festival />
              <UserFooter />
            </Route>
            {/* Donation  */}
            <Route path="/donate/ashadhi" exact>
              <UserNav />
              <DonationAshadhi />
              <UserFooter />
            </Route>
            <Route path="/donate/gau-seva" exact>
              <UserNav />
              <DonationGau />
              <UserFooter />
            </Route>
            <Route path="/donate/anna-daan" exact>
              <UserNav />
              <DonationAnna />
              <UserFooter />
            </Route>
            <Route path="/donate/gita-daan" exact>
              <UserNav />
              <DonationGita />
              <UserFooter />
            </Route>
            <Route path="/donate/pilgrims-seva" exact>
              <UserNav />
              <DonationPilgrims />
              <UserFooter />
            </Route>
            <Route path="/kumbh" exact>
              <UserNav />
              <MahaKumbh />
              <UserFooter />
            </Route>
            <Route path="/donate/youth-preaching" exact>
              <UserNav />
              <DonationYouth />
              <UserFooter />
            </Route>
            <Route path="/donate/general" exact>
              <UserNav />
              <DonationGenral />
              <UserFooter />
            </Route>
            <Route path="/news" exact>
              <UserNav />
              <News />
              <UserFooter />
            </Route>
            <Route path="/blog" exact>
              <UserNav />
              <Blog />
              <UserFooter />
            </Route>
            <Route path="/contact" exact>
              <UserNav />
              <Contact />
              <UserFooter />
            </Route>
            <Route path="/blog/:readmorelink" exact>
              <UserNav />
              <Blog />
              <UserFooter />
            </Route>
            {/* Media  */}
            <Route path="/media/video-gallery" exact>
              <UserNav />
              <VideoGallary />
              <UserFooter />
            </Route>
            <Route path="/media/photo-gallery" exact>
              <UserNav />
              <PhotoGallery />
              <UserFooter />
            </Route>
            <Route path="/media/daily-darshan" exact>
              <UserNav />
              <DailyDarshan />
              <UserFooter />
            </Route>
            {/* Booking */}
            <Route path="/book/accommodation" exact>
              <UserNav />
              <Acco />
              <UserFooter />
            </Route>
            <Route path="/book/prashad" exact>
              <UserNav />
              <Food />
              <UserFooter />
            </Route>
            <Route path="/book/vehicle" exact>
              <UserNav />
              <Vehicle />
              <UserFooter />
            </Route>
            <Route path="/book/dham-darshan" exact>
              <UserNav />
              <DhamDarshan />
              <UserFooter />
            </Route>
            {/* About  */}
            <Route path="/about/iskcon-pandharpur" exact>
              <UserNav />
              <AboutIP />
              <UserFooter />
            </Route>
            <Route path="/about/iskcon" exact>
              <UserNav />
              <AboutISKCON />
              <UserFooter />
            </Route>
            <Route path="/about/lokanath-swami-maharaj" exact>
              <UserNav />
              <AboutLNS />
              <UserFooter />
            </Route>
            <Route path="/about/shrila-prabhupada" exact>
              <UserNav />
              <AboutPrabhupada />
              <UserFooter />
            </Route>

            {isAuthenticated && role === "user" && (
              <>
                <Route path="/" component={UserNav} />
                <Route path="/" component={UserHome} />
                <Redirect from="/*" to="/" />
              </>
            )}
            {isAuthenticated && role === "admin" && (
              <>
                <Route
                  path="/admin"
                  render={(props) => (
                    <AdminNavbar
                      {...props}
                      sendDataToRoute={this.handleDatafromNav}
                    />
                  )}
                />
                <div
                  className={`main-content ${
                    this.state.navExpanded ? "expanded" : ""
                  }`}
                >
                  <Route path="/admin/dashboard" component={AdminDashboard} />
                  <Route
                    path="/admin/Activity"
                    exact={true}
                    component={ActivityAdmin}
                  />
                  <Route
                    path="/admin/Slider"
                    exact={true}
                    component={SliderAdmin}
                  />
                  <Route
                    path="/admin/Festival"
                    exact={true}
                    component={Festival}
                  />
                  <Route
                    path="/admin/Festival1"
                    exact={true}
                    component={Festival1Admin}
                  />
                  <Route
                    path="/admin/donation"
                    exact={true}
                    component={DonationAdminAdmin}
                  />
                </div>
              </>
            )}
            {isAuthenticated && role === "superadmin" && (
              <>
                <Route path="/superadmin" component={SuperAdminNavbar} />
                <Route
                  path="/superadmin/dashboard"
                  component={SuperAdminDashboard}
                />
                <Redirect from="/superadmin" to="/superadmin/dashboard" />
              </>
            )}
            {!isAuthenticated && <Redirect to="/login" />}
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default Routing;
